import { z } from 'zod';

export const updateBusinessProfileRequestSchema = z.object({
  companies: z
    .object({
      name: z.string().trim(),
      businessType: z.string().trim(),
      ein: z.string().trim(),
      phone: z.string().trim(),
      address1: z.string().trim(),
      address2: z.string().trim().nullable().optional(),
      city: z.string().trim(),
      zip: z.string().trim(),
      county: z.string().trim(),
      state: z.string().trim(),
    })
    .array(),
});

export type UpdateBusinessProfileRequest = z.infer<
  typeof updateBusinessProfileRequestSchema
>;
