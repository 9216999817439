import { Chip, Tooltip } from '@mui/material'
import {
  dateFormatter,
  formatCurrency,
  getToolTipTitle,
  limitDecimals,
  lrpDateFormat,
  makeCoverageCommodityLabel,
  pages,
  makeFullName,
} from 'common'
import { InsureIQPolicyTypes, InsureIQStatusStyles } from '@harvestiq/constants'
import { dayjs } from '@harvestiq/utils'
import { getEntityName } from '@harvestiq/iiq/common'
import Decimal from 'decimal.js'
import { InfoTooltip } from '../components/InfoTooltip'

export const calculationTypes = {
  cwt: 'cwt',
  head: 'head',
  total: 'total',
}

export const priceToggleOptions = {
  cash: 'cash',
  futures: 'futures',
}
const probableIndemnityTooltipTitle =
  'Gross Margin Change is estimated given current market conditions and does not guarantee a specific outcome.'

const lgmCoverageSubTableColumns = [
  { field: 'marketingMonth', label: 'Marketing Month' },
  { field: 'numberofHead', label: 'Number of Head' },
  {
    field: 'premium',
    label: 'Producer Premium',
    render: monthPremium => formatCurrency(monthPremium),
  },
  {
    field: 'grossMarginGuarantee',
    label: 'Gross Margin Guarantee',
    render: monthGrossMarginGuarantee =>
      formatCurrency(monthGrossMarginGuarantee),
  },
  {
    field: 'grossMargin',
    label: 'Gross Margin',
    render: (monthGrossMargin, { actualGrossMargin }) => (
      <Tooltip title={getToolTipTitle('grossMargin', actualGrossMargin)}>
        <span>{formatCurrency(monthGrossMargin)}</span>
      </Tooltip>
    ),
  },
  {
    field: 'grossMarginChange',
    label: (
      <InfoTooltip title={probableIndemnityTooltipTitle}>
        Gross Margin Change
      </InfoTooltip>
    ),
    render: (indemnity, { actualGrossMarginChange }) => {
      return (
        <Tooltip
          title={getToolTipTitle('grossMarginChange', actualGrossMarginChange)}
        >
          <span>{formatCurrency(indemnity)}</span>
        </Tooltip>
      )
    },
  },
]

export const calculationOptionsByPolicyType = {
  [InsureIQPolicyTypes.LRP]: [
    calculationTypes.cwt,
    calculationTypes.head,
    calculationTypes.total,
  ],
  [InsureIQPolicyTypes.LGM]: [calculationTypes.head, calculationTypes.total],
}

export const calculatorsByCalculationType = {
  cwt: () => value => limitDecimals(value),

  head: (targetWeight = 1) => {
    return value => {
      return limitDecimals(value * targetWeight)
    }
  },

  total: (targetWeight, numberOfHeads) => {
    return value => {
      return limitDecimals(numberOfHeads * targetWeight * value)
    }
  },
}

const calculateMarketingMonthValues = (marketingMonth, calculationType) => {
  const {
    subsidy,
    premium,
    actualIndemnity,
    projectedIndemnity,
    actualGrossMargin,
    projectedGrossMargin,
    numberofHead,
    grossMarginGuarantee,
    actualGrossMarginChange,
    projectedGrossMarginChange,
  } = marketingMonth

  const calculator = calculatorsByCalculationType[calculationType](
    1,
    numberofHead,
  )

  return {
    ...marketingMonth,
    subsidy: calculator(subsidy),
    premium: calculator(premium),
    // Ensure that indemnity is never displayed as a negative value on a monthly basis
    indemnity: Math.max(0, calculator(actualIndemnity ?? projectedIndemnity)),
    grossMarginChange: calculator(
      actualGrossMarginChange ?? projectedGrossMarginChange,
    ),
    grossMargin: calculator(actualGrossMargin ?? projectedGrossMargin),
    grossMarginGuarantee: calculator(grossMarginGuarantee),
  }
}

const calculateLgmValues = (coverage, calculationType) => {
  const { marketingMonths, deductible } = coverage.details

  const subTable = {
    columns: lgmCoverageSubTableColumns,
    rows: marketingMonths.map(marketingMonth =>
      calculateMarketingMonthValues(marketingMonth, calculationType),
    ),
  }

  return {
    subTable,
    deductible,
  }
}

export const restructureCoverages = (
  coverages = [],
  priceType = priceToggleOptions.cash,
  calculationType = calculationTypes.head,
) => {
  return coverages.map(rawCoverage => {
    const coverage = { ...rawCoverage }
    const { details, totalValues } = coverage
    const {
      actualEndingValue,
      actualIndemnity,
      futuresIndemnity,
      coveragePrice,
      endDate,
      expectedEndingValue,
      expectedIndemnity,
      numberofHead = totalValues.numberofHeadTotal,
      premium,
      price,
      targetWeight,
    } = details

    const calculator = calculatorsByCalculationType[calculationType](
      targetWeight,
      numberofHead,
    )

    Object.assign(coverage, { targetWeight, numberofHead })

    if (coverage.type === InsureIQPolicyTypes.LGM) {
      Object.assign(coverage, calculateLgmValues(coverage, calculationType))
    } else {
      Object.assign(coverage, {
        premium: calculator(premium),
        cashIndemnity: calculator(actualIndemnity ?? expectedIndemnity),
        futuresIndemnity: calculator(actualIndemnity ?? futuresIndemnity),
        endingValue: calculator(actualEndingValue ?? expectedEndingValue),
        cashPrice: price ? calculator(price.cash.adjustedClosePrice) : 0,
        futuresPrice: price ? calculator(price.futures.adjustedClosePrice) : 0,
        actualEndingValue: calculator(actualEndingValue),
        coveragePrice: calculator(coveragePrice),
        endDate,
      })
    }
    return { details, totalValues, ...coverage }
  })
}

export const statusColumn = {
  id: 'status',
  label: 'Status',
  isSortable: true,
  render: status => {
    return (
      <Chip label={status} color={InsureIQStatusStyles[status]} size="small" />
    )
  },
}

export const ownersColumn = entitiesEnabled => {
  if (entitiesEnabled) {
    return {
      id: 'owner',
      label: 'Entity Name',
      render: (_, { userOwner, companyOwner, entityHistory }) => {
        return getEntityName(entityHistory)
      },
    }
  }
  return {
    id: 'owner',
    label: 'Owner',
    render: (_, { userOwner, companyOwner }) => {
      const userName = userOwner
        ? makeFullName(userOwner.firstName, userOwner.lastName)
        : ''
      const companyName = companyOwner ? companyOwner.name : ''
      const ownerNames = [userName, companyName].filter(Boolean).join(', ')
      return ownerNames
    },
  }
}

export const commodityColumn = {
  id: 'commodity',
  label: 'Commodity',
  isSortable: true,
  render: (commodity, { commodityType }) =>
    makeCoverageCommodityLabel(commodity, commodityType),
}

export const salesEffectiveDateColumn = {
  id: 'salesEffectiveDate',
  label: 'Sales Effective Date',
  render: salesEffectiveDate =>
    dateFormatter(salesEffectiveDate, lrpDateFormat),
}

export const endDateColumn = {
  id: 'endDate',
  label: (
    <InfoTooltip title="Specific Coverage Endorsement End Date indicates when the given LRP Position will close.">
      SCE End Date
    </InfoTooltip>
  ),
}

export const deductibleColumn = {
  id: 'deductible',
  label: 'Deductible',
  render: deductible => formatCurrency(deductible),
}

export const targetWeightColumn = {
  id: 'targetWeight',
  label: 'Target Weight',
}

export const numberofHeadColumn = {
  id: 'numberofHead',
  label: 'Total Number of Head',
}

export const premiumColumn = {
  id: 'premium',
  label: 'Premium',
  render: premium => formatCurrency(premium),
}

export const coveragePriceColumn = {
  id: 'coveragePrice',
  label: 'Coverage Price',
  render: coveragePrice => formatCurrency(coveragePrice),
}

export const endingValueColumn = {
  id: 'endingValue',
  label: (
    <InfoTooltip
      title={
        <>
          Latest relevant cash value. See the{' '}
          <a
            href={`${pages.faq.path}?faqs=what-are-the-calculations-for-actual-ending-value-and-expected-ending-value`}
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>{' '}
          for more detail.
        </>
      }
    >
      Cash Price
    </InfoTooltip>
  ),
  render: (endingValue, row) => {
    return <span>{formatCurrency(endingValue)}</span>
  },
}

export const generateCashPriceTooltip = values => {
  if (!values) {
    return ''
  }

  const { name, symbol, reportPeriod } = values
  const textLine1 = `CME ${name || ''} Cash (${symbol})`
  const textLine2 = reportPeriod
    ? `${dayjs(reportPeriod).format('YYYY-MM-DD')} Close Price`
    : ''
  return (
    <>
      {textLine1} <br />
      {textLine2}
    </>
  )
}

export const generateFuturesPriceTooltip = values => {
  if (!values) {
    return ''
  }

  const { name, symbol, reportPeriod } = values
  const textLine1 = `CME ${name || ''} Futures (${symbol})`
  const textLine2 = reportPeriod
    ? `${dayjs(reportPeriod).format('YYYY-MM-DD')} Close Price`
    : ''
  return (
    <>
      {textLine1} <br />
      {textLine2}
    </>
  )
}

export const cashPriceColumn = {
  id: 'cashPrice',
  label: (
    <InfoTooltip
      title={
        <>
          Latest relevant cash value. See the{' '}
          <a
            href={`${pages.faq.path}?faqs=what-are-the-calculations-for-actual-ending-value-and-expected-ending-value`}
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>{' '}
          for more detail.
        </>
      }
    >
      Cash Price
    </InfoTooltip>
  ),
  render: (cashPrice, { details }) => {
    if (!cashPrice) {
      return '-'
    }
    return (
      <Tooltip title={generateCashPriceTooltip(details?.price?.cash)}>
        <span>{formatCurrency(cashPrice)}</span>
      </Tooltip>
    )
  },
}

export const futuresPriceColumn = {
  id: 'futuresPrice',
  label: 'Futures Price',
  render: (futruesPrice, { details }) => {
    if (!futruesPrice) {
      return '-'
    }
    return (
      <Tooltip title={generateFuturesPriceTooltip(details?.price?.futures)}>
        <span>{formatCurrency(futruesPrice)}</span>
      </Tooltip>
    )
  },
}

export const actualEndingValueColumn = {
  id: 'actualEndingValue',
  label: 'Actual Ending Value',
  render: actualEndingValue => {
    return actualEndingValue ? formatCurrency(actualEndingValue) : '-'
  },
}

export const cashIndemnityColumn = {
  id: 'cashIndemnity',
  label: (
    <InfoTooltip title={probableIndemnityTooltipTitle}>
      Projected Indemnity
    </InfoTooltip>
  ),
  render: indemnity => <span>{formatCurrency(indemnity)}</span>,
}

export const futuresIndemnityColumn = {
  id: 'futuresIndemnity',
  label: (
    <InfoTooltip title={probableIndemnityTooltipTitle}>
      Projected Indemnity
    </InfoTooltip>
  ),
  render: indemnity => <span>{formatCurrency(indemnity)}</span>,
}

export const projectedIndemnityColumn = {
  id: 'projectedIndemnity',
  label: 'Projected Indemnity',
  render: (_, { details }) => {
    let totalGrossMarginChange = 0
    details.marketingMonths.forEach(marketingMonth => {
      if (marketingMonth?.actualGrossMarginChange) {
        totalGrossMarginChange =
          totalGrossMarginChange +
          marketingMonth.actualGrossMarginChange * marketingMonth.numberofHead
      } else {
        totalGrossMarginChange =
          totalGrossMarginChange +
          marketingMonth.projectedGrossMarginChange *
            marketingMonth.numberofHead
      }
    })
    const projectedIndemnity = Math.max(0, totalGrossMarginChange)

    return <span>{formatCurrency(projectedIndemnity)}</span>
  },
}

export const isLrpCoverageWatched = (coverage, quote) => {
  if (!coverage || !quote) {
    return false
  }

  const isWatched =
    coverage.commodity === quote.commodity &&
    coverage.commodityType === quote.commodityType &&
    coverage.details.endDate === quote.endDate &&
    new Decimal(coverage.details.coveragePrice).eq(quote.coveragePrice) &&
    new Decimal(coverage.details.coverageLevel).eq(quote.coverageLevel) &&
    new Decimal(coverage.details.premium).eq(quote.producerPremium)

  return isWatched
}
