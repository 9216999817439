import { useMemo } from 'react'
import {
  FormControl,
  ListItemText,
  Tooltip,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText,
} from '@mui/material'
import { Clear as ClearButton } from '@mui/icons-material'
import { MultiSelectAll, MultiSelectClear } from 'common'
import { useToast } from '../../contexts'

import styles from './inputs.module.scss'

const MSC_ITEM_HEIGHT = 40
const MSC_ITEM_PADDING_TOP = 28
const MSC_MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: MSC_ITEM_HEIGHT * 4.5 + MSC_ITEM_PADDING_TOP,
    },
  },
}

export const MultipleSelectCheckmarks = ({
  label,
  options: optionsProps = [],
  value: valueProps = [],
  onChange,
  enableSelectAll = false,
  helperText,
  error = false,
  limit = false,
  CheckboxProps: _,
  ...props
}) => {
  const { errorToast } = useToast()
  const values = useMemo(() => {
    if (typeof valueProps === 'string') {
      return []
    }
    return valueProps.toString() === optionsProps.toString()
      ? [MultiSelectAll]
      : valueProps
  }, [valueProps, optionsProps])

  const handleChange = ({ target: { name, value } }) => {
    if (limit && value.length > limit) {
      errorToast(`Please limit the number of selected choices to ${limit}.`)
      return
    }
    const newValue = value[value.length - 1]
    if (newValue === MultiSelectAll) {
      return onChange({ target: { name, value: [MultiSelectAll] } })
    }
    if (newValue !== MultiSelectAll && value.length > 1) {
      const valuesWithoutAll = value.filter(v => v !== MultiSelectAll)
      return onChange({ target: { name, value: valuesWithoutAll } })
    }
    onChange({ target: { name, value } })
  }

  const options = useMemo(() => {
    if (enableSelectAll) {
      return [MultiSelectAll, ...optionsProps]
    }
    return optionsProps.map(option => option?.label ?? option)
  }, [optionsProps, enableSelectAll])

  const handleClearAll = () => {
    onChange({ target: { name: props.name, value: [] } })
  }

  return (
    <FormControl variant="outlined" className="flex-1" error={error}>
      {(values.length && !props.disabled) > 0 && (
        <Tooltip title={MultiSelectClear}>
          <IconButton className={styles.clearButton} onClick={handleClearAll}>
            <ClearButton />
          </IconButton>
        </Tooltip>
      )}
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={values}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={selected => {
          return selected === optionsProps
            ? MultiSelectAll
            : selected.join(', ')
        }}
        MenuProps={MSC_MENU_PROPS}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={values.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
