import { GeneratedAlways, Insertable, Selectable, Updateable } from 'kysely';
import { DbTimestampGenerated } from '@harvestiq/utils';
import { UserRoleIds } from './user-role-ids';
import { expectTypeOf } from 'expect-type';
import { z } from 'zod';
import { zTimestamp } from '@harvestiq/zod';

export interface UserRolesTable {
  id: GeneratedAlways<string>;
  userId: string;
  roleId: UserRoleIds;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
}

export type DbUserRole = Selectable<UserRolesTable>;
export type DbUserRoleInsert = Insertable<UserRolesTable>;
export type DbUserRoleUpdate = Updateable<UserRolesTable>;

export const dbUserRoleSchema = z.object({
  id: z.string(),
  userId: z.string(),
  roleId: z.nativeEnum(UserRoleIds),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
type DbUserRoleFromSchema = z.infer<typeof dbUserRoleSchema>;
expectTypeOf<keyof DbUserRoleFromSchema>().toEqualTypeOf<keyof DbUserRole>();
expectTypeOf<DbUserRole>().toMatchTypeOf<DbUserRoleFromSchema>();

export const dbUserRoleInsertSchema = dbUserRoleSchema
  .omit({
    id: true,
  })
  .extend({
    createdAt: zTimestamp().optional(),
    updatedAt: zTimestamp().optional(),
  });
type DbUserRoleInsertFromSchema = z.infer<typeof dbUserRoleInsertSchema>;
expectTypeOf<keyof DbUserRoleInsertFromSchema>().toEqualTypeOf<
  keyof DbUserRoleInsert
>();
expectTypeOf<DbUserRoleInsert>().toMatchTypeOf<DbUserRoleInsertFromSchema>();

export const dbUserRoleUpdateSchema = dbUserRoleInsertSchema.partial();
type DbUserRoleUpdateFromSchema = z.infer<typeof dbUserRoleUpdateSchema>;
expectTypeOf<keyof DbUserRoleUpdateFromSchema>().toEqualTypeOf<
  keyof DbUserRoleUpdate
>();
expectTypeOf<DbUserRoleUpdate>().toMatchTypeOf<DbUserRoleUpdateFromSchema>();
