const tooltipTitleMap = {
  // First index is expected/projected and second index is actual
  endingValue: ['Expected Ending Value', 'Actual Ending Value'],
  indemnity: ['Projected Indemnity', 'Actual Indemnity'],
  grossMargin: ['Projected Gross Margin', 'Actual Gross Margin'],
  grossMarginChange: [
    'Projected Gross Margin Change',
    'Actual Gross Margin Change',
  ],
}

export const getToolTipTitle = (
  key: keyof typeof tooltipTitleMap,
  value: number | null | undefined,
) => {
  const isActual = value !== null && value !== undefined
  if (isActual) {
    return tooltipTitleMap[key]?.[1] // index 1
  }
  return tooltipTitleMap[key]?.[0] // index 0
}

export const agentSignedDateRequiredMessage =
  'Agent sign date is required for submission. It has been set to the date of AIP submission.'
