import React, { createContext, useContext, ReactNode } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useMediaQuery } from 'react-responsive'
import { DbAppSettings } from '@harvestiq/iiq/common'
import { orgAppSettingsDefaults, mergeOrgAppSettings } from 'common'
import BaseLoading from '../../components/Loading/BaseLoading'
import { getSettings } from '../../http'

type SettingsResponse = {
  appSettings: DbAppSettings
  [key: string]: any
}

export type AppSettingsContextType = {
  appSettings: DbAppSettings
  isLoaded: boolean
  isMobile: boolean
  refetch: () => Promise<any>
  orgAppSettingsDefaults: Record<string, any>
  mergeOrgAppSettings: (settings: Record<string, any>) => Record<string, any>
  [key: string]: any
}

export const AppSettingsContext = createContext<
  AppSettingsContextType | undefined
>(undefined)

const useAppSettingsProvider = (): AppSettingsContextType => {
  const queryKey = 'getSettings'

  const {
    data: {
      appSettings = orgAppSettingsDefaults,
      ...restSettings
    } = {} as SettingsResponse,
    refetch,
    isLoading,
  } = useQuery<SettingsResponse>(
    [queryKey],
    async () => {
      // @todo remove this cast once we move to InsureIQApiClient
      const response = (await getSettings()) as { data: SettingsResponse }

      if (!response) {
        console.error(
          'Unexpected error when fetching settings. No axios response.',
        )
        return {} as SettingsResponse
      }

      return response.data
    },
    {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  const isMobile = useMediaQuery({ query: '(max-width: 770px)' })

  return {
    appSettings: mergeOrgAppSettings(appSettings) as unknown as DbAppSettings,
    isLoaded: !isLoading,
    isMobile,
    refetch,
    orgAppSettingsDefaults,
    mergeOrgAppSettings,
    ...restSettings,
  }
}

type AppSettingsProviderProps = {
  children: ReactNode
}

export const AppSettingsProvider = ({ children }: AppSettingsProviderProps) => {
  const appSettings = useAppSettingsProvider()

  return (
    <AppSettingsContext.Provider value={appSettings}>
      {appSettings.isLoaded ? children : <BaseLoading />}
    </AppSettingsContext.Provider>
  )
}

export const useAppSettings = (): AppSettingsContextType => {
  const context = useContext(AppSettingsContext)
  if (context === undefined) {
    throw new Error('useAppSettings must be used within an AppSettingsProvider')
  }
  return context
}
