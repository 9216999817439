import { GeneratedAlways, Selectable, Insertable, Updateable } from 'kysely';
import { MediaMetaData, mediaMetaDataSchema } from './media-meta-data';
import { z } from 'zod';
import { expectTypeOf } from 'expect-type';
import { DbTimestampGenerated, DbTimestampNullable } from '@harvestiq/utils';

export interface MediaTable {
  id: GeneratedAlways<string>;
  extension: string;
  metaData: MediaMetaData | null;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
  deletedAt: DbTimestampNullable;
}

export type DbMedia = Selectable<MediaTable>;
export type DbMediaInsert = Insertable<MediaTable>;
export type DbMediaUpdate = Updateable<MediaTable>;

export const dbMediaSchema = z.object({
  id: z.string(),
  extension: z.string(),
  metaData: mediaMetaDataSchema.nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});
expectTypeOf<DbMedia>().toMatchTypeOf<z.output<typeof dbMediaSchema>>();
