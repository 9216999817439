import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { BusinessType } from './business-types';
import { z } from 'zod';
import { expectTypeOf } from 'expect-type';
import { dbUserCompanySchema } from '../user-companies/db-user-company';

export interface CompaniesTable {
  id: GeneratedAlways<string>;
  name: string | null;
  phone: string | null;
  address1: string | null;
  address2: string | null;
  zip: string | null;
  city: string | null;
  county: string | null;
  state: string | null;
  country: string | null;
  ein: string | null;
  businessType: BusinessType | null;
  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
}

export type DbCompany = Selectable<CompaniesTable>;
export type DbCompanyInsert = Insertable<CompaniesTable>;
export type DbCompanyUpdate = Updateable<CompaniesTable>;

export const dbCompanySchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  phone: z.string().nullable(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  city: z.string().nullable(),
  zip: z.string().nullable(),
  county: z.string().nullable(),
  state: z.string().nullable(),
  country: z.string().nullable(),
  ein: z.string().nullable(),
  businessType: z.nativeEnum(BusinessType).nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});

export const dbCompanyInsertSchema = dbCompanySchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export const dbCompanyUpdateSchema = dbCompanySchema.omit({
  id: true,
  createdAt: true,
});

// Verify schema type matches kysely
expectTypeOf<DbCompany>().toEqualTypeOf<z.infer<typeof dbCompanySchema>>();
