import { LivestockInsuranceTypes } from '@harvestiq/constants';
import { zCoercedBoolean, zIsoDate } from '@harvestiq/zod';
import { z } from 'zod';
import { emailPolicyDataSchema } from './email-policy-data';
import { recipientSchema } from '../users/recipients';
import { lgmDetailsMarketingMonthsSchema } from './coverage-details';

const coverageIndemnityLgmMarketingMonthSchema =
  // Extend the DB LGM Details schema with fields added from calculation of indemnity
  lgmDetailsMarketingMonthsSchema.extend({
    actualGrossMargin: z.number().optional(),
    projectedGrossMargin: z.number(),
    actualIndemnity: z.number().optional(),
    projectedGrossMarginChange: z.number(),
    isProjectedActual: z.boolean().optional(),
  });

export const coverageIndemnityDataSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(LivestockInsuranceTypes.LRP),
    coverageId: z.string(),
    salesEffectiveDate: zIsoDate(),
    assignedAgent: z
      .object({
        email: z.string(),
        name: z.string(),
        phone: z.string().optional(),
      })
      .optional(),
    coveragePricePerHead: z.number(), // ?decimal?
    targetWeight: z.coerce.number(),
    endDate: zIsoDate(),
    endorsementLength: z.number(),
    numberOfHead: z.coerce.number(), // can be string or number in DB
    actualEndingValuePerHead: z.number(),
    calculatedIndemnityPerHead: z.number(),
    commodity: z.string(),
    commodityType: z.string(),
    policy: emailPolicyDataSchema.optional(),
  }),
  z.object({
    type: z.literal(LivestockInsuranceTypes.LGM),
    coverageId: z.string(),
    salesEffectiveDate: zIsoDate(),
    assignedAgent: z
      .object({
        email: z.string(),
        name: z.string(),
        phone: z.string().optional(),
      })
      .optional(),
    endDate: zIsoDate(),
    triggeringEndDate: zIsoDate(),
    deductible: z.number(),
    liveCattleTargetCwtPerHead: z.number().nullable(),
    feederCattleTargetCwtPerHead: z.number().nullable(),
    cornTargetBuPerHead: z.number().nullable(),
    marketingMonths: z.array(coverageIndemnityLgmMarketingMonthSchema),
    grossMarginGuarantee: z.number(),
    totalActualGrossMargin: z.number(),
    projectedIndemnity: z.number(),
    guaranteedGrossMarginPerHead: z.number(),
    grossMarginPerHead: z.number(),
    calculatedIndemnityPerHead: z.number(),
    numberOfHead: z.coerce.number(),
    commodity: z.string(),
    commodityType: z.string(),
    policy: emailPolicyDataSchema.optional(),
  }),
]);

export type CoverageIndemnityData = z.infer<typeof coverageIndemnityDataSchema>;

export const coverageIndemnityApiResponseSchema = z.object({
  coverages: z
    .object({
      to: z.array(recipientSchema),
      cc: z.array(recipientSchema).optional(),
      coverageIndemnityData: coverageIndemnityDataSchema,
    })
    .array(),
});

export type CoverageIndemnityApiResponse = z.infer<
  typeof coverageIndemnityApiResponseSchema
>;

export const coverageIndemnityApiRequestSchema = z.object({
  type: z.nativeEnum(LivestockInsuranceTypes),
  daysBefore: z.coerce.number().optional(),
  sendPreview: z.string().optional(),
  enableEntities: zCoercedBoolean().optional(),
});

export type CoverageIndemnityApiRequest = z.infer<
  typeof coverageIndemnityApiRequestSchema
>;
