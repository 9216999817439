import { useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'
import { localStorage } from '../utils/localStorage'
import { getEnv } from '../config'
import { useAppSettings } from '../contexts/index'
import { DbAppSettings } from '@harvestiq/iiq/common'

export enum FeatureFlags {
  ENABLE_LGM_REPORT = 'enableLgmReport',
  ENABLE_ENTITIES = 'enableEntities',
  ENABLE_LGM_INDEMNITY = 'enableLgmIndemnity',
  EXAMPLE_FF = 'exampleFF',
}

const FEATURE_FLAG_DEFAULTS_BY_ENV: Record<
  string,
  Record<FeatureFlags, boolean>
> = {
  local: {
    [FeatureFlags.ENABLE_LGM_REPORT]: true,
    [FeatureFlags.ENABLE_ENTITIES]: true,
    [FeatureFlags.ENABLE_LGM_INDEMNITY]: true,
    [FeatureFlags.EXAMPLE_FF]: true,
  },
  test: {
    [FeatureFlags.ENABLE_LGM_REPORT]: true,
    [FeatureFlags.ENABLE_ENTITIES]: true,
    [FeatureFlags.ENABLE_LGM_INDEMNITY]: true,
    [FeatureFlags.EXAMPLE_FF]: true,
  },
  staging: {
    [FeatureFlags.ENABLE_LGM_REPORT]: true,
    [FeatureFlags.ENABLE_ENTITIES]: false,
    [FeatureFlags.ENABLE_LGM_INDEMNITY]: true,
    [FeatureFlags.EXAMPLE_FF]: true,
  },
  production: {
    [FeatureFlags.ENABLE_LGM_REPORT]: true,
    [FeatureFlags.ENABLE_ENTITIES]: false,
    [FeatureFlags.ENABLE_LGM_INDEMNITY]: true,
    [FeatureFlags.EXAMPLE_FF]: false,
  },
}

/**
 * Returns a boolean featureEnabled value if the passed feature flag is enabled for the user.
 * e.g. const { featureEnabled: showCoolFeature } = useFeatureFlags(FeatureFlags.ENABLE_COOL_FEATURE)
 * Feature flags are enabled for the user by passing a query param with the flag name and 'true':
 * e.g. ?ENABLE_COOL_FEATURE=true
 * Feature flag query params are evaluated once on load where the feature flag is stored in local storage.
 * Feature flags will be enabled for the user until local storage is cleared or flag is disabled.
 * Feature flags can be disabled by passing a query param with the flag name and anything other than 'true':
 * e.g. ?ENABLE_COOL_FEATURE or ?ENABLE_COOL_FEATURE=false
 * Backend feature flags are defined in appSettings and will override local storage and default feature flags.
 * @param featureFlagName
 * @returns { featureEnabled: boolean, setFeatureFlagsFromSearchParams: () => void}
 */
export const useFeatureFlags = (featureFlagName: FeatureFlags) => {
  const env = getEnv()
  const [searchParams] = useSearchParams()
  const { appSettings } = useAppSettings()

  const backendFeatureFlag = appSettings?.[
    featureFlagName as keyof DbAppSettings
  ] as boolean | undefined

  const localStorageFeatureFlag = localStorage.get(featureFlagName) as
    | boolean
    | undefined

  const defaultFeatureFlag = FEATURE_FLAG_DEFAULTS_BY_ENV[env][featureFlagName]

  const featureEnabled =
    // If there is a backend feature flag use it above all else
    backendFeatureFlag ??
    // Fallback to local storage explicitly set by user
    localStorageFeatureFlag ??
    // Fallback to default feature flags by environment
    defaultFeatureFlag

  const setFeatureFlagsFromSearchParams = useCallback(() => {
    searchParams.forEach((value, key) => {
      if (Object.values(FeatureFlags).includes(key as FeatureFlags)) {
        if (value.toLowerCase() === 'true') {
          localStorage.set(key, true)
        } else {
          localStorage.remove(key)
        }
      }
    })
  }, [searchParams])
  return { featureEnabled, setFeatureFlagsFromSearchParams }
}
