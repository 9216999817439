import { GeneratedAlways, Selectable, Insertable, Updateable } from 'kysely';
import {
  RecordMediaMediaTypes,
  RecordMediaRecordTypes,
} from './record-media-types';
import { expectTypeOf } from 'expect-type';
import { z } from 'zod';
import { DbTimestampGenerated } from '@harvestiq/utils';

export interface RecordMediaTable {
  id: GeneratedAlways<string>;
  recordId: string;
  recordType: RecordMediaRecordTypes;
  mediaId: string;
  mediaType: RecordMediaMediaTypes;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
}

export type DbRecordMedia = Selectable<RecordMediaTable>;
export type DbRecordMediaInsert = Insertable<RecordMediaTable>;
export type DbRecordMediaUpdate = Updateable<RecordMediaTable>;

export const dbRecordMediaSchema = z.object({
  id: z.string(),
  recordId: z.string(),
  recordType: z.nativeEnum(RecordMediaRecordTypes),
  mediaId: z.string(),
  mediaType: z.nativeEnum(RecordMediaMediaTypes),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
expectTypeOf<DbRecordMedia>().toMatchTypeOf<
  z.output<typeof dbRecordMediaSchema>
>();
