import { LegacySequelizeUser } from '@harvestiq/iiq/common'
import { api, insureIQApiClient } from '../utils'

export const getUserById = (userId = 'me') =>
  insureIQApiClient.getUserById({ userId })

export const getUserPolicies = (
  userId = 'me',
  params: Record<string, any> = {},
) => api.get(`/users/${userId}/policies`, params).then(resp => resp?.data)

export const getUserProfile = (userId = 'me') =>
  api.get(`/users/${userId}`).then(({ data, errors }) => ({ data, errors }))

export const updateUserProfileByUserId = (
  userId: string,
  body: Record<string, any>,
) => api.patch(`/users/${userId}/profile`, body)

export const updateUserPersonalInformations = (
  userId: string,
  params: Record<string, any>,
) => api.patch(`/profile/personal/${userId}`, params)

export const updateUserBusinessInformation = (
  userId: string,
  params: Record<string, any>,
) => api.patch(`/profile/business/${userId}`, params)

export const getUsers = (query: Record<string, any> = {}) =>
  api.get('/users', query).then(resp => resp?.data)

export const addUser = (query: Record<string, any> = {}): Promise<any> =>
  api.post('/users', query).then(resp => resp)

export const searchUsers = (query: Record<string, any> = {}) =>
  api.get('/users/search', query).then(resp => resp?.data)

export const updateUser = (
  userId: string,
  params: Record<string, any>,
): Promise<{ data: { user: LegacySequelizeUser } }> =>
  api.patch(`/users/${userId}`, params)

export const updateEmail = (email: string) =>
  api.patch('/users/me/update-email', { email })

export const updateUserRoles = (params: Record<string, any>) =>
  api
    .patch(`/users/roles`, params)
    .then(({ data, errors }) => ({ data, errors }))

export const blockUsers = (query: Record<string, any>) =>
  api.delete(`/users`, query).then(({ data, errors }) => ({ data, errors }))

export const deleteUserById = (userId: string) =>
  api.delete(`/users/${userId}`).then(({ data, errors }) => ({ data, errors }))

export const updateUsers = (
  query: Record<string, any>,
  updates: Record<string, any> = {},
): Promise<any> =>
  api
    .patch(`/users`, { where: query, ...updates })
    .then(({ data, errors }) => ({ data, errors }))

export const removeUserRole = (userId: string, roleId: string) =>
  api
    .delete(`/users/${userId}/roles/${roleId}`)
    .then(({ data, errors }) => ({ data, errors }))

export const addUserRole = (userId: string, roleId: string) =>
  api
    .post(`/users/${userId}/roles`, { roleId })
    .then(({ data, errors }) => ({ data, errors }))

export const getUserRoles = (userId: string) =>
  api
    .get(`/users/${userId}/roles`)
    .then(({ data, errors }) => ({ data, errors }))

export const assignAgent = (userId: string, agentId: string) =>
  api.patch(`/users/${userId}/agents`, { agentId })
