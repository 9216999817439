import { z } from 'zod';
import {
  GeneratedAlways,
  Selectable,
  Insertable,
  Updateable,
  ColumnType,
} from 'kysely';
import {
  RmaEntityTypeCodes,
  RmaProgramIndicatorCodes,
  RmaTaxIdTypes,
} from '@harvestiq/constants';
import { zDecimalRequired, zTimestamp } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';
import {
  DbColumnDecimalNotNull,
  DbTimestampGenerated,
  DbTimestampNullable,
  MakeUndefinedOptional,
} from '@harvestiq/utils';

export interface EntitiesTable {
  id: GeneratedAlways<string>;
  ownerId: ColumnType<string | null>;
  agentId: ColumnType<string | null>;
  type: ColumnType<RmaEntityTypeCodes>;
  ownershipShare: DbColumnDecimalNotNull;
  taxId: ColumnType<string | null>;
  taxIdType: ColumnType<RmaTaxIdTypes | null>;
  email: ColumnType<string | null>;
  addressLine1: ColumnType<string | null>;
  addressLine2: ColumnType<string | null>;
  city: ColumnType<string | null>;
  stateAbbreviation: ColumnType<string | null>;
  zip: ColumnType<string | null>;
  zipExtensionCode: ColumnType<string | null>;
  phone: ColumnType<string | null>;
  phoneNumberExceptionCode: ColumnType<string | null>;
  phoneExtensionNumber: ColumnType<string | null>;
  internationalAddress: ColumnType<string | null>;
  internationalCountryCode: ColumnType<string | null>;
  internationalPhoneCountryCode: ColumnType<string | null>;
  programIndicator: ColumnType<RmaProgramIndicatorCodes | null>;
  firstName: ColumnType<string | null>;
  middleName: ColumnType<string | null>;
  lastName: ColumnType<string | null>;
  suffix: ColumnType<string | null>;
  title: ColumnType<string | null>;
  picCode: ColumnType<string | null>;
  locationStateCode: ColumnType<string | null>;
  authorizedRepPoa: ColumnType<string | null>;
  stateOfIncorporation: ColumnType<string | null>;
  businessName: ColumnType<string | null>;
  importSourceId: ColumnType<string | null>;
  mergedIntoEntityId: ColumnType<string | null>;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
  deletedAt: DbTimestampNullable;
}

export type DbEntity = Selectable<EntitiesTable>;
export type DbEntityInsert = MakeUndefinedOptional<Insertable<EntitiesTable>>;
export type DbEntityUpdate = MakeUndefinedOptional<Updateable<EntitiesTable>>;

export const dbEntitySchema = z.object({
  id: z.string().uuid(),
  ownerId: z.string().uuid().nullable(),
  agentId: z.string().uuid().nullable(),
  type: z.nativeEnum(RmaEntityTypeCodes),
  ownershipShare: zDecimalRequired(),
  taxId: z.string().nullable(),
  taxIdType: z.nativeEnum(RmaTaxIdTypes).nullable(),
  email: z.string().nullable(),
  addressLine1: z.string().nullable(),
  addressLine2: z.string().nullable(),
  city: z.string().nullable(),
  stateAbbreviation: z.string().nullable(),
  zip: z.string().nullable(),
  zipExtensionCode: z.string().nullable(),
  phone: z.string().nullable(),
  phoneNumberExceptionCode: z.string().nullable(),
  phoneExtensionNumber: z.string().nullable(),
  internationalAddress: z.string().nullable(),
  internationalCountryCode: z.string().nullable(),
  internationalPhoneCountryCode: z.string().nullable(),
  programIndicator: z.nativeEnum(RmaProgramIndicatorCodes).nullable(),
  firstName: z.string().nullable(),
  middleName: z.string().nullable(),
  lastName: z.string().nullable(),
  suffix: z.string().nullable(),
  title: z.string().nullable(),
  picCode: z.string().nullable(),
  locationStateCode: z.string().nullable(),
  authorizedRepPoa: z.string().nullable(),
  stateOfIncorporation: z.string().nullable(),
  businessName: z.string().nullable(),
  importSourceId: z.string().nullable(),
  mergedIntoEntityId: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});
expectTypeOf<DbEntity>().toMatchTypeOf<z.output<typeof dbEntitySchema>>();

export const dbEntityInsertSchema = dbEntitySchema
  .omit({
    id: true,
  })
  .partial()
  .extend({
    ownershipShare: zDecimalRequired(),
    type: z.nativeEnum(RmaEntityTypeCodes),
    taxIdType: z.nativeEnum(RmaTaxIdTypes).nullish(),
    createdAt: zTimestamp().optional(),
    updatedAt: zTimestamp().optional(),
    deletedAt: zTimestamp().nullable().optional(),
  });
type CreateEntity = z.input<typeof dbEntityInsertSchema>;
expectTypeOf<keyof CreateEntity>().toEqualTypeOf<keyof DbEntityInsert>();
expectTypeOf<DbEntityInsert>().toMatchTypeOf<CreateEntity>();

export const dbEntityUpdateSchema = dbEntityInsertSchema.partial();
type UpdateEntity = z.input<typeof dbEntityUpdateSchema>;

expectTypeOf<keyof UpdateEntity>().toEqualTypeOf<keyof DbEntityUpdate>();
expectTypeOf<DbEntityUpdate>().toMatchTypeOf<UpdateEntity>();

// Remove the sensitive fields from the entity
export const safeDbEntitySchema = dbEntitySchema.omit({
  taxId: true,
});
export type SafeDbEntity = Selectable<z.infer<typeof safeDbEntitySchema>>;
