import { z } from 'zod';

export const updatePersonalProfileRequestSchema = z
  .object({
    firstName: z.string().trim().nullable(),
    middleName: z.string().trim().nullable(),
    lastName: z.string().trim().nullable(),
    email: z.string().trim().email(),
    maritalStatus: z.enum(['Not Married', 'Married']).nullable(),
    ssn: z.string().trim().nullable(),
    timezone: z.string().trim().nullable(),
    avatar: z.string().trim().nullable(),
  })
  .partial();

export type UpdatePersonalProfileRequest = z.infer<
  typeof updatePersonalProfileRequestSchema
>;
