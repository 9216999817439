export enum EntityOwnerEntityTypes {
  user = 'User',
  company = 'Company', // TODO: remove when turning entities FF ON (its not used)
  policy = 'Policy',
  coverage = 'Coverage',
  customer = 'customer',
}

export enum EntityOwnerOwnerTypes {
  user = 'User',
  agent = 'agent',
  company = 'Company',
}
