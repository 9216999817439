import React from 'react'
import { Snackbar, Alert, SnackbarProps } from '@mui/material'

export type ToastProps = {
  severity: 'error' | 'warning' | 'info' | 'success'
  handleClose: () => void
  text?: string
  content?: React.ReactNode
} & SnackbarProps

export function Toast({
  open,
  severity,
  autoHideDuration = 3000,
  handleClose,
  text,
  content = text,
  ...props
}: ToastProps) {
  const vertical = 'top'
  const horizontal = 'right'
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      {...props}
    >
      <Alert onClose={handleClose} severity={severity}>
        {content}
      </Alert>
    </Snackbar>
  )
}
