import {
  LivestockInsuranceTypes,
  MonthNames,
  RmaLgmCommodityTypeCodes,
  RmaLgmCommodityTypes,
  RmaLrpCommodityTypeCodes,
  RmaLrpCommodityTypes,
  DayOfWeekIndexToNameMap,
  FrequencyDayOfWeek,
  CampaignFrequency,
  CampaignMarketingMonthsAll,
} from '@harvestiq/constants';
import { nowCdtDayjs } from '@harvestiq/utils';
import { Campaign } from './Campaigns';
import { campaignResponseSchema } from './campaign-schema';
import { z } from 'zod';
import Decimal from 'decimal.js';
import { CalendarDate } from '@harvestiq/zod';

const campaignMarketingMonths = MonthNames();

export const typeguardLRPCommodityType = (
  insuranceType: LivestockInsuranceTypes,
  commodityType: RmaLrpCommodityTypeCodes | RmaLgmCommodityTypeCodes
): commodityType is RmaLrpCommodityTypeCodes => {
  if (insuranceType === LivestockInsuranceTypes.LRP) {
    return Object.keys(RmaLrpCommodityTypes).includes(commodityType.toString());
  }
  return false;
};

export const typeguardLGMCommodityType = (
  insuranceType: LivestockInsuranceTypes,
  commodityType: RmaLrpCommodityTypeCodes | RmaLgmCommodityTypeCodes
): commodityType is RmaLgmCommodityTypeCodes => {
  if (insuranceType === LivestockInsuranceTypes.LGM) {
    return Object.keys(RmaLgmCommodityTypes).includes(commodityType.toString());
  }
  return false;
};

export type ParsedCampaign = Omit<
  Campaign,
  | 'marketingMonths'
  | 'marketingLengths'
  | 'commodity'
  | 'commodityType'
  | 'frequencyDays'
> & {
  marketingMonths?: string[] | null;
  marketingLengths?: number[] | null;
  frequencyDays?: string[];
  recipientIds: string[];
  commodity: string;
  commodityType: string;
};

export const parseCampaignData = (
  campaignData?: z.input<typeof campaignResponseSchema>
): ParsedCampaign | undefined => {
  if (!campaignData) {
    return undefined;
  }
  const { recipients, ...campaign } = campaignData;

  const marketingMonthNames = campaign?.marketingMonths?.length
    ? campaign.marketingMonths.map((monthNumber) => {
        return campaignMarketingMonths[monthNumber];
      })
    : null;

  let commodityInfo = null;
  const insuranceType = campaign.insuranceType;
  const commodityType = campaign.commodityType;
  if (typeguardLRPCommodityType(insuranceType, commodityType)) {
    commodityInfo = RmaLrpCommodityTypes[commodityType];
  } else if (typeguardLGMCommodityType(insuranceType, commodityType)) {
    commodityInfo = RmaLgmCommodityTypes[commodityType];
  } else {
    throw new Error(
      `Invalid commodity type ${commodityType} for insurance type ${insuranceType}`
    );
  }

  let frequencyDays: string[] = [];

  if (campaign.frequencyDays) {
    frequencyDays = campaign.frequencyDays.map((day) => {
      return DayOfWeekIndexToNameMap[day];
    });
  } else {
    if (insuranceType === LivestockInsuranceTypes.LRP) {
      frequencyDays =
        campaign.frequency === CampaignFrequency.DAILY
          ? ['All']
          : [FrequencyDayOfWeek.MONDAY];
    } else {
      frequencyDays = [FrequencyDayOfWeek.THURSDAY];
    }
  }

  // const frequencyDays = campaign.frequencyDays;

  // TODO: just use zod...?
  const parsedCampaign: ParsedCampaign = {
    ...campaign,
    recipients,
    ...(commodityInfo && {
      commodity: commodityInfo.sgCommodityName,
      commodityType: commodityInfo.sgName,
    }),
    frequencyDays,
    endDate: campaign.endDate as CalendarDate | null,
    sentAt: campaign.sentAt as CalendarDate | null,
    lastSed: campaign?.lastSed as CalendarDate | null,
    s3Filename: campaign?.s3Filename ?? null,
    marketingMonths: marketingMonthNames ?? null,
    recipientIds: recipients.map(({ userId }) => userId),
    targetWeight: campaign.targetWeight
      ? new Decimal(campaign.targetWeight)
      : null,
    liveCattleTargetCwtPerHead: campaign.liveCattleTargetCwtPerHead
      ? new Decimal(campaign.liveCattleTargetCwtPerHead)
      : null,
    feederCattleTargetCwtPerHead: campaign.feederCattleTargetCwtPerHead
      ? new Decimal(campaign.feederCattleTargetCwtPerHead)
      : null,
    cornTargetBuPerHead: campaign.cornTargetBuPerHead
      ? new Decimal(campaign.cornTargetBuPerHead)
      : null,
  };

  return parsedCampaign;
};

export const generateMarketingMonthsFromLengths = (
  marketingLengths: number[]
) => {
  const currentMonthNumber = nowCdtDayjs().month();
  if (!marketingLengths || marketingLengths.length === 0) {
    return CampaignMarketingMonthsAll;
  }
  const marketingMonths = marketingLengths.map((length) => {
    const marketingMonthVal = currentMonthNumber + length;
    return marketingMonthVal > 11 ? marketingMonthVal - 12 : marketingMonthVal;
  });

  return marketingMonths;
};
