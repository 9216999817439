import {
  InsureIQLrpCommodityTypeNames,
  RmaLrpCommodityTypeCodes,
  InsureIQCoverageStatus,
  InsureIQPolicyTypes,
  InsureIQLrpCommodityNames,
} from '@harvestiq/constants';
import {
  GeneratedAlways,
  ColumnType,
  Selectable,
  Insertable,
  Updateable,
} from 'kysely';
import {
  LrpCoverageDetails,
  lrpCoverageDetailsSchema,
} from './coverage-details';
import { dbSharedEndorsementSchema } from './shared';
import { z } from 'zod';
import { zDate, zDecimalRequired } from '@harvestiq/zod';
import Decimal from 'decimal.js';
import { expectTypeOf } from 'expect-type';

/**
 * Represents an eventual LRP Endorsement table in the database.
 */
export type LrpEndorsementsTable = {
  id: GeneratedAlways<string>;
  agentSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  commodity: InsureIQLrpCommodityNames;
  commodityType: InsureIQLrpCommodityTypeNames;
  commodityTypeCode: RmaLrpCommodityTypeCodes | null;
  createdBy: string | null;
  customerSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  details: LrpCoverageDetails;
  lgmIndemnityDetails: never;
  externalStatus: string | null;
  isExternallyManaged: boolean;
  reinsuranceYear: number;
  policyId: string | null;
  entityHistoryId: string | null;
  salesEffectiveDate: string;
  status: InsureIQCoverageStatus;
  submittedToAipAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  type: InsureIQPolicyTypes;
  watchedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
  importSourceId: string | null;
  totalPremium: ColumnType<Decimal | null, number | null, number | null>;
  subsidyTotal: ColumnType<Decimal | null, number | null, number | null>;
  producerPremiumTotal: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;

  // LRP only fields
  coverageLevel: ColumnType<Decimal | null, number | null, number | null>;
  coveragePricePerCwt: ColumnType<Decimal | null, number | null, number | null>;
  coveragePricePerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  coveragePriceTotal: ColumnType<Decimal | null, number | null, number | null>;
  endorsementLength: ColumnType<Decimal | null, number | null, number | null>;
  headCount: ColumnType<Decimal | null, number | null, number | null>;
  producerPremiumPerCwt: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  producerPremiumPerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  subsidyPerCwt: ColumnType<Decimal | null, number | null, number | null>;
  subsidyPerHead: ColumnType<Decimal | null, number | null, number | null>;
  subsidyRate: ColumnType<Decimal | null, number | null, number | null>;
  targetWeightQuantityCwt: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  totalPremiumPerCwt: ColumnType<Decimal | null, number | null, number | null>;
  totalPremiumPerHead: ColumnType<Decimal | null, number | null, number | null>;

  // LGM Only fields
  // TODO: Remove once we have two separate tables
  liveCattleTargetCwtPerHead: null;
  feederCattleTargetCwtPerHead: null;
  cornTargetBuPerHead: null;
  deductible: never;
  targetMarketingsTotal: never;
  expectedGrossMarginTotal: never;
  grossMarginGuaranteeTotal: never;
  lgmGrossMarginChangeTotal: never;
  lgmActualGrossMarginTotal: never;
  lgmActualIndemnity: never;
  lgmNetActualIndemnity: never;
  lgmProjectedGrossMarginTotal: never;
  lgmProjectedIndemnity: never;
  lgmNetProjectedIndemnity: never;
};

export type DbLrpEndorsement = Selectable<LrpEndorsementsTable>;
export type DbLrpEndorsementInsert = Insertable<LrpEndorsementsTable>;
export type DbLrpEndorsementUpdate = Updateable<LrpEndorsementsTable>;

export const dbLrpEndorsementSchema = dbSharedEndorsementSchema.extend({
  commodity: z.nativeEnum(InsureIQLrpCommodityNames),
  type: z.literal(InsureIQPolicyTypes.LRP),
  commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
  commodityTypeCode: z.nativeEnum(RmaLrpCommodityTypeCodes).nullable(),
  details: lrpCoverageDetailsSchema,
  importSourceId: z.string().nullable(),
  coveragePriceTotal: zDecimalRequired().nullable(),
  coveragePricePerHead: zDecimalRequired().nullable(),
  coveragePricePerCwt: zDecimalRequired().nullable(),
  producerPremiumTotal: zDecimalRequired().nullable(),
  producerPremiumPerHead: zDecimalRequired().nullable(),
  producerPremiumPerCwt: zDecimalRequired().nullable(),
  targetWeightQuantityCwt: zDecimalRequired().nullable(),
  endorsementLength: zDecimalRequired().nullable(),
  headCount: zDecimalRequired().nullable(),
  totalPremium: zDecimalRequired().nullable(),
  totalPremiumPerCwt: zDecimalRequired().nullable(),
  totalPremiumPerHead: zDecimalRequired().nullable(),
  subsidyTotal: zDecimalRequired().nullable(),
  subsidyPerCwt: zDecimalRequired().nullable(),
  subsidyPerHead: zDecimalRequired().nullable(),
  subsidyRate: zDecimalRequired().nullable(),
  coverageLevel: zDecimalRequired().nullable(),
  // LGM Only fields
  // TODO: Remove once we have two separate tables
  liveCattleTargetCwtPerHead: z.null(),
  feederCattleTargetCwtPerHead: z.null(),
  cornTargetBuPerHead: z.null(),
});

expectTypeOf<keyof DbLrpEndorsement>().toEqualTypeOf<
  keyof z.output<typeof dbLrpEndorsementSchema>
>();

export const dbLrpEndorsementInsertSchema = dbLrpEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
    coveragePriceTotal: z.number().nullable(),
    coveragePricePerHead: z.number().nullable(),
    coveragePricePerCwt: z.number().nullable(),
    producerPremiumTotal: z.number().nullable(),
    producerPremiumPerHead: z.number().nullable(),
    producerPremiumPerCwt: z.number().nullable(),
    targetWeightQuantityCwt: z.number().nullable(),
    endorsementLength: z.number().nullable(),
    headCount: z.number().nullable(),
    totalPremium: z.number().nullable(),
    totalPremiumPerCwt: z.number().nullable(),
    totalPremiumPerHead: z.number().nullable(),
    subsidyTotal: z.number().nullable(),
    subsidyPerCwt: z.number().nullable(),
    subsidyPerHead: z.number().nullable(),
    subsidyRate: z.number().nullable(),
    coverageLevel: z.number().nullable(),
  });

// keys match
expectTypeOf<keyof DbLrpEndorsementInsert>().toEqualTypeOf<
  keyof z.output<typeof dbLrpEndorsementInsertSchema>
>();

export const dbLrpEndorsementUpdateSchema = dbLrpEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
    coveragePriceTotal: z.number().nullable(),
    coveragePricePerHead: z.number().nullable(),
    coveragePricePerCwt: z.number().nullable(),
    producerPremiumTotal: z.number().nullable(),
    producerPremiumPerHead: z.number().nullable(),
    producerPremiumPerCwt: z.number().nullable(),
    targetWeightQuantityCwt: z.number().nullable(),
    endorsementLength: z.number().nullable(),
    headCount: z.number().nullable(),
    totalPremium: z.number().nullable(),
    totalPremiumPerCwt: z.number().nullable(),
    totalPremiumPerHead: z.number().nullable(),
    subsidyTotal: z.number().nullable(),
    subsidyPerCwt: z.number().nullable(),
    subsidyPerHead: z.number().nullable(),
    subsidyRate: z.number().nullable(),
    coverageLevel: z.number().nullable(),
  })
  .partial();

// keys match
expectTypeOf<keyof DbLrpEndorsementUpdate>().toEqualTypeOf<
  keyof z.output<typeof dbLrpEndorsementUpdateSchema>
>();
