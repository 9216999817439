import { useCallback } from 'react'
import { TextField } from '@mui/material'
import isEmail from 'validator/lib/isEmail'

export const Email = ({ error, helperText, onChange, ...props }) => {
  const isValid =
    props.value === undefined ||
    props.value === '' ||
    (isEmail(props.value) && !error)
  const localProps = {
    helperText: helperText || (!isValid && 'Invalid email'),
    error: !isValid,
  }

  const handleChange = useCallback(
    event => {
      const normalizedValue = (event.target.value ?? '').toLowerCase().trim() // ensure email is always lowercase and trimmed

      const regex = /^[^@s]+@[^@s]+.[^@s]+$/
      if (regex.test(normalizedValue)) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = normalizedValue
      }

      onChange(event)
    },
    [onChange],
  )

  return <TextField onChange={handleChange} {...localProps} {...props} />
}
