import { DbJsonB, DbTimestampGenerated } from '@harvestiq/utils';
import { zTimestamp } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';
import { Insertable, Selectable, Updateable } from 'kysely';
import { z } from 'zod';
import { ColorScheme, colorSchemeSchema } from './color-scheme';

export type AppSettingsTable = {
  // ID is not a UUID, but a string (name) that is unique across all orgs
  id: string;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
  name: string | null;
  fullLogo: string | null;
  compactLogo: string | null;
  favicon: string | null;
  loadingIndicator: string | null;
  phone: string | null;
  email: string | null;
  domain: string | null;
  sendgridDomainId: string | null;
  // TODO: Figure out what this is and type it
  sendgridDomainDns: DbJsonB<{ [key: string]: { valid: boolean } } | null>;
  colorScheme: DbJsonB<ColorScheme | null>;
  isActive: boolean | null;
  isAdmin: boolean | null;
  gaTrackingId: string | null;
  hubspotAccountId: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  senderEmail: string | null;
  timezone: string | null;
  fmhApiKey: string | null;
  fmhSigningKey: string | null;
  organizationType: string;
  parentOrgId: string | null;
  stockguardSlackChannel: string | null;
  enableEntities: boolean | null;
  enableNotificationCrons: boolean | null;
};

export type DbAppSettings = Selectable<AppSettingsTable>;
export type DbAppSettingsInsert = Insertable<AppSettingsTable>;
export type DbAppSettingsUpdate = Updateable<AppSettingsTable>;

export const dbAppSettingsSchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  name: z.string().nullable(),
  fullLogo: z.string().url().nullable(), // url to logo
  compactLogo: z.string().url().nullable(), // url to logo
  favicon: z.string().url().nullable(), // url to favicon
  loadingIndicator: z.string().nullable(),
  phone: z.string().nullable(),
  email: z.string().email().nullable(),
  domain: z.string().nullable(),
  sendgridDomainId: z.string().nullable(),
  // TODO: Figure out what this is and fully type it
  sendgridDomainDns: z
    .record(z.string(), z.object({ valid: z.boolean() }))
    .nullable(),
  colorScheme: colorSchemeSchema.nullable(),
  isActive: z.boolean().nullable(),
  isAdmin: z.boolean().nullable(),
  gaTrackingId: z.string().nullable(),
  hubspotAccountId: z.string().nullable(),
  addressLine1: z.string().nullable(),
  addressLine2: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  senderEmail: z.string().email().nullable(),
  timezone: z.string().nullable(),
  fmhApiKey: z.string().nullable(),
  fmhSigningKey: z.string().nullable(),
  organizationType: z.string(),
  parentOrgId: z.string().nullable(),
  stockguardSlackChannel: z.string().nullable(),
  enableEntities: z.boolean().nullable(),
  enableNotificationCrons: z.boolean().nullable(),
});
type DbAppSettingsSchemaType = z.output<typeof dbAppSettingsSchema>;
// Ensure the output of the READ schema matches the SELECT schema
expectTypeOf<DbAppSettings>().toMatchTypeOf<DbAppSettingsSchemaType>();
// Ensure keys match
expectTypeOf<keyof DbAppSettingsSchemaType>().toEqualTypeOf<
  keyof DbAppSettings
>();

const dbAppSettingsInsertSchema = dbAppSettingsSchema
  .partial()
  .required({ id: true, organizationType: true })
  .extend({
    createdAt: zTimestamp().optional(),
    updatedAt: zTimestamp().optional(),
  });
type DbAppSettingsInsertSchemaType = z.output<typeof dbAppSettingsInsertSchema>;
// Ensure types match
expectTypeOf<DbAppSettingsInsert>().toMatchTypeOf<DbAppSettingsInsertSchemaType>();
// Ensure keys match
expectTypeOf<keyof DbAppSettingsInsertSchemaType>().toEqualTypeOf<
  keyof DbAppSettingsInsert
>();

const dbAppSettingsUpdateSchema = dbAppSettingsInsertSchema.partial();
type DbAppSettingsUpdateSchemaType = z.input<typeof dbAppSettingsUpdateSchema>;
// Ensure types match
expectTypeOf<DbAppSettingsUpdate>().toMatchTypeOf<DbAppSettingsUpdateSchemaType>();
// Ensure keys match
expectTypeOf<keyof DbAppSettingsUpdateSchemaType>().toEqualTypeOf<
  keyof DbAppSettingsUpdate
>();
