import { z } from 'zod';
import {
  dbUserProfileSchema,
  safeDbUserProfileSchema,
} from '../user-profiles/db-user-profile';
import { dbUserSchema, safeDbUserSchema } from './db-user';
import { dbUserRoleSchema } from '../user-roles/db-user-role';

export const userSchema = dbUserSchema.extend({
  profile: dbUserProfileSchema,
});
export type UserSchema = z.infer<typeof userSchema>;

export const safeUserSchema = safeDbUserSchema.extend({
  profile: safeDbUserProfileSchema.nullable(),
  roles: z.array(dbUserRoleSchema),
});
export type SafeUser = z.infer<typeof safeUserSchema>;
