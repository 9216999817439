export enum RecordMediaRecordTypes {
  user = 'User',
  policy = 'Policy',
  coverage = 'Coverage',
}

export enum RecordMediaMediaTypes {
  application = 'application',
  avatar = 'avatar',
  coverage = 'coverage',
}
