import { Insertable } from 'kysely';
import { z } from 'zod';

// TODO: this should use .pick/.extend from the existing app-settings schema
export const orgAppSettingsSchema = z.object({
  id: z.string(),
  domain: z.string(),
  name: z.string(),
  fullLogo: z.string().url(), // url to logo
  senderEmail: z.string().email().nullish(),
  schemaName: z.string(),
  colorScheme: z.object({
    primary: z.string(),
    secondary: z.string(),
    success: z.string(),
    info: z.string(),
    warning: z.string(),
    error: z.string(),
  }),
  email: z.string().email().nullish(),
  timezone: z.string().nullish(),

  // TODO: remove optional and handle types
  isActive: z.boolean().nullable().optional(),
  isAdmin: z.boolean().nullable().optional(),
  enableEntities: z.boolean().nullable(),
  enableNotificationCrons: z.boolean().nullable(),
});

export type OrgAppSettings = z.infer<typeof orgAppSettingsSchema>;

export const allOrgAppSettingsResponseSchema = z.object({
  data: z.array(orgAppSettingsSchema),
});

export type AllOrgAppSettingsResponse = z.infer<
  typeof allOrgAppSettingsResponseSchema
>;

export type OrgAppSettingsInsert = Insertable<OrgAppSettings>;
