import { z } from 'zod';

export const colorSchemeSchema = z.object({
  primary: z.string(),
  secondary: z.string(),
  success: z.string(),
  info: z.string(),
  warning: z.string(),
  error: z.string(),
});

export type ColorScheme = z.infer<typeof colorSchemeSchema>;
