import { expectTypeOf } from 'expect-type';
import {
  GeneratedAlways,
  Selectable,
  Insertable,
  Updateable,
  ColumnType,
} from 'kysely';
import { z } from 'zod';
import { UserProfilePhones, userProfilePhonesSchema } from './phones';
import { DbTimestampGenerated, DbTimestampNullable } from '@harvestiq/utils';
import { zTimestamp } from '@harvestiq/zod';

export interface UserProfilesTable {
  id: GeneratedAlways<string>;
  userId: string | null;
  dob: DbTimestampNullable;
  farmerType: string | null;
  liveStock: string | null;
  maritalStatus: string | null;
  // FIXME: Phones are stored as a JSONB array, need to allow insert and updates as strings
  // See for issues with this: https://github.com/kysely-org/kysely/issues/137
  phones: ColumnType<
    UserProfilePhones,
    string | UserProfilePhones,
    string | UserProfilePhones
  >;
  ssn: string | null;
  updatedAt: DbTimestampGenerated;
  createdAt: DbTimestampGenerated;
  deletedAt: DbTimestampNullable;
}

export type DbUserProfile = Selectable<UserProfilesTable>;
export type DbUserProfileInsert = Insertable<UserProfilesTable>;
export type DbUserProfileUpdate = Updateable<UserProfilesTable>;

export const dbUserProfileSchema = z.object({
  id: z.string().uuid(),
  userId: z.string().nullable(),
  dob: z.coerce.date().nullable(),
  farmerType: z.string().nullable(),
  liveStock: z.string().nullable(),
  maritalStatus: z.string().nullable(),
  phones: userProfilePhonesSchema,
  ssn: z.string().nullable(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});
expectTypeOf<keyof z.input<typeof dbUserProfileSchema>>().toEqualTypeOf<
  keyof DbUserProfile
>();
expectTypeOf<DbUserProfile>().toEqualTypeOf<
  z.infer<typeof dbUserProfileSchema>
>();

// Remove sensitive fields from user profile
export const safeDbUserProfileSchema = dbUserProfileSchema.omit({ ssn: true });
export type SafeDbUserProfile = Selectable<
  z.infer<typeof safeDbUserProfileSchema>
>;

export const dbUserProfileInsertSchema = dbUserProfileSchema
  .omit({ id: true })
  .partial()
  .extend({
    // Phones cannot be undefined
    phones: userProfilePhonesSchema.or(z.string()),
    dob: zTimestamp().nullable().optional(),
    createdAt: zTimestamp().optional(),
    updatedAt: zTimestamp().optional(),
    deletedAt: zTimestamp().nullable().optional(),
  });
type DbUserProfileInsertFromSchema = z.input<typeof dbUserProfileInsertSchema>;
expectTypeOf<keyof DbUserProfileInsertFromSchema>().toEqualTypeOf<
  keyof DbUserProfileInsert
>();
expectTypeOf<DbUserProfileInsert>().toMatchTypeOf<DbUserProfileInsertFromSchema>();

export const dbUserProfileUpdateSchema = dbUserProfileInsertSchema.partial();
type DbUserProfileUpdateFromSchema = z.input<typeof dbUserProfileUpdateSchema>;
expectTypeOf<keyof DbUserProfileUpdateFromSchema>().toEqualTypeOf<
  keyof DbUserProfileInsert
>();
expectTypeOf<DbUserProfileInsert>().toMatchTypeOf<DbUserProfileUpdateFromSchema>();
