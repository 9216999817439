import { RmaEntityTypeCodes } from './EntityTypeCodes';

// See: RMA https://pubfs-rma.fpac.usda.gov/pub/References/insurance_control_elements/PASS/2024/2024_D00012_IceEntityType_YTD.txt
export const RmaEntityTypeDescriptions: Record<RmaEntityTypeCodes, string> = {
  [RmaEntityTypeCodes.PublicSchools]: 'Public Schools',
  [RmaEntityTypeCodes.BureauOfIndianAffairsAndIndianTribeTreaties]:
    'Bureau of Indian Affairs and Indian Tribe Treaties',
  [RmaEntityTypeCodes.Corporation]: 'Corporation',
  [RmaEntityTypeCodes.Estates]: 'Estates',
  [RmaEntityTypeCodes.NonProfitOrTaxExemptOrganizations]:
    'Non-Profit or Tax-Exempt Organizations',
  [RmaEntityTypeCodes.TransferOfRightToIndemnity]:
    'Transfer of Right to Indemnity (SBI only)',
  [RmaEntityTypeCodes.ReceiverOrLiquidator]: 'Receiver or Liquidator',
  [RmaEntityTypeCodes.StateLocalGovernment]: 'State/Local Government',
  [RmaEntityTypeCodes.Individuals]: 'Individuals',
  [RmaEntityTypeCodes.JointOperationsVenturesAndCoOwnerships]:
    'Joint operations/ventures and co-ownerships',
  [RmaEntityTypeCodes.Partnership]: 'Partnership',
  [RmaEntityTypeCodes.TrustRevocable]: 'Trust - Revocable',
  [RmaEntityTypeCodes.SpousalMarried]: 'Spousal/Married',
  [RmaEntityTypeCodes.TrustIrrevocable]: 'Trust - Irrevocable',
  [RmaEntityTypeCodes.IndividualOperatingAsABusiness]:
    'Individual Operating as a Business',
  [RmaEntityTypeCodes.LimitedLiabilityCompany]: 'Limited Liability Company',
};

export const rmaEntityTypeOptions: { key: string; value: string }[] =
  Object.entries(RmaEntityTypeDescriptions)
    .filter(([key, _]) => {
      return key !== RmaEntityTypeCodes.TransferOfRightToIndemnity; // SBI only
    })
    .map(([key, value]) => ({
      key: key,
      value: value,
    }));

export const rmaEntityTypeOptionsForSBIs: { key: string; value: string }[] =
  Object.entries(RmaEntityTypeDescriptions).map(([key, value]) => ({
    key: key,
    value: value,
  }));
