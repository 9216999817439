import { useCallback } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { FormHelperText } from '@mui/material'

export const parseDate = value => {
  if (!value) {
    return null
  }
  return value instanceof Date ? value : new Date(value)
}

export const DatePickerInput = ({
  name,
  value,
  onChange,
  required,
  helperText = '',
  error,
  ...rest
}) => {
  const handleDateChange = useCallback(
    newValue => {
      if (!newValue) {
        onChange({ target: { name, value: '' } })
        return
      }
      onChange({ target: { name, value: newValue } })
    },
    [onChange, name],
  )

  const dateValue = parseDate(value)

  return (
    <div className="width-100 flex flex-col flex-1">
      <DatePicker
        {...rest}
        value={dateValue || null}
        onAccept={newValue => handleDateChange(newValue)}
        onChange={val => handleDateChange(val)}
        fullWidth
        required={required}
        error={error}
        slotProps={{ textField: { name } }}
      />
      {!!helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  )
}

export default DatePickerInput
